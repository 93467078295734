import React from "react"
import { css } from "linaria"

export default function ImageSize({ children }) {
  return <div className={hackyImageSize}>{children}</div>
}

const hackyImageSize = css`
  margin: 0 auto;
  + p .gatsby-resp-image-wrapper {
    max-width: 50% !important;
  }
`
