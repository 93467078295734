import { graphql, Link } from "gatsby"
import { css } from "linaria"
import { styled } from "linaria/react"
import React from "react"
import Layout from "./layout"
import Heading from "./Layout/heading"
import MaxWidth from "./Layout/maxWidth"
import SEO from "./seo"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-mdx"
import { MDXProvider } from "@mdx-js/react"
import ImageSize from "./ImageSize"
import HR from "./Layout/hr"

export default function BlogPost({ data: { mdx }, pageContext, ...rest }) {
  const {
    frontmatter: { title, featuredImage, date },
  } = mdx

  const { nextPost, prevPost } = pageContext

  // Use these to construct pagination

  const fluid = featuredImage && featuredImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={mdx.frontmatter.title} />
      <Heading>
        <h1 style={{ textAlign: "center" }}>{title}</h1>
        <div className={postedDate}>
          Posted on <span>{date}</span>
        </div>
      </Heading>

      {fluid && (
        <FeatureImage>
          <Img fluid={fluid} />
        </FeatureImage>
      )}
      <MaxWidth>
        <div className={blogPost}>
          <MDXProvider components={{ HalfImg: ImageSize }}>
            <MDXRenderer>{mdx.code.body}</MDXRenderer>
          </MDXProvider>
        </div>
        <HR m="3em 0" />

        <Pagination next={nextPost} prev={prevPost} />
      </MaxWidth>
    </Layout>
  )
}

function Pagination({ next, prev }) {
  return (
    <div className={pagination}>
      {prev && (
        <Link to={prev.fields.slug} className="prev">
          Prev: {prev.frontmatter.title}
        </Link>
      )}
      {next && (
        <Link to={next.fields.slug} className="next">
          Next: {next.frontmatter.title}
        </Link>
      )}
    </div>
  )
}

const pagination = css`
  margin-bottom: 3em;
  display: flex;
  .next,
  .prev {
    display: block;
  }
  .next {
    margin-left: auto;
    text-align: right;
  }
`

const blogPost = css`
  max-width: 80ch;
  line-height: 1.75;
  margin: 0 auto;
`

const postedDate = css`
  font-size: 0.75em;
  margin: 0 auto;
  text-align: center;
  span {
    font-weight: 600;
  }
`

const FeatureImage = styled.div`
  margin: 0 auto;
  max-width: 120ch;
`

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
